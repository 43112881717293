<template>
  <div class="relative">
    <div class="absolute inset-0 bg-zinc-900">
      <img
        class="w-full h-full object-cover opacity-30"
        src="@/assets/hero.webp"
        alt=""
      />
      <div
        class="absolute inset-0 w-full h-full absolute top-0 left-0 z-20 bg-gradient-to-r to-transparent from-zinc-900"
        aria-hidden="true"
      />
    </div>
    <Container>
      <div class="py-8 relative z-20  ">
        <h1
          class="
            text-4xl
            font-extrabold
            tracking-tight
            text-white
            sm:text-5xl
            capitalize
            lg:text-6xl
          "
        >
          {{ title }}
        </h1>
        <p v-if="description" class="mt-6 text-xl text-indigo-100 max-w-3xl">
          {{ description }}
        </p>
      </div>
    </Container>
  </div>
</template>

<script>
import Container from "@/components/layouts/Container.vue";
export default {
  components: { Container },
  name: "Header",
  props: {
    title: String,
    description: String,
  },
};
</script>
