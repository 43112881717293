<template>

<div class="relative bg-zinc-900">
    <div class="h-56 bg-accent sm:h-72 md:absolute md:left-0 md:h-full md:w-1/2">
      <img class="w-full h-full object-cover" src="@/assets/cta.webp" alt="" />
    </div>
    <div class="relative max-w-7xl mx-auto px-4 py-20 sm:px-6 lg:px-8 lg:py-28">
      <div class="md:ml-auto md:w-1/2 md:pl-10">
        <p class="mt-2 text-white text-3xl font-extrabold tracking-tight sm:text-4xl">Our Reputation</p>
        <p class="mt-3 text-lg text-gray-300">
          Our reputation speaks for itself because we strongly believe that service is more than delivering your shipment, it's our business.
        </p>
        <div class="mt-8">
          <div class="inline-flex rounded-md shadow">
            <router-link to="/contact" class="inline-flex items-center justify-center px-5 py-3 border border-transparent text-lg font-medium rounded-md text-gray-900 bg-white hover:bg-gray-50">
              Get In Touch
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
<!-- 
  <div class="relative">
    
    <div class="inset-0 absolute bg-accent-hover opacity-90 mix-blend-multiply"></div>
    <div class="absolute inset-0 bg-black opacity-50"></div>
    <div
      class="
        max-w-7xl
        mx-auto
        py-12
        px-4
        sm:px-6
        lg:py-24 lg:px-8 lg:flex lg:items-center lg:justify-between relative z-20
      "
    >
    <div>
      <h2
        class="
          text-3xl
          font-extrabold
          tracking-tight
          text-white
          md:text-4xl
        "
      >
        <span class="block">Our Reputation</span>
      </h2>
      <p class="md:w-1/2 text-white">Our reputation speaks for itself because we strongly believe that service is more than delivering your shipment, it's our business.</p>
      </div>
      <div class="mt-8 flex lg:mt-0 lg:flex-shrink-0">
        <Button path="/contact">Get In Touch</Button>
      </div>
    </div>
  </div> -->
</template>

<script>
import Button from "@/components/base/Button.vue";
export default {
  components: { Button },
};
</script>
